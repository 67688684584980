<template>
  <el-card>
    <div class="vg_mb_8">
      <el-date-picker
        v-model="dateRange"
        class="vg_ml_8 vg_mr_8 topDateRangeSearch"
        end-placeholder="退单结束日期"
        range-separator="至"
        size="small"
        start-placeholder="退单开始日期"
        type="daterange"
        value-format="timestamp"
      />
      <el-button class="vd_export" size="small" type="primary" @click="getTableData()">查询</el-button>
      <el-button class="vd_export" size="small" type="success" @click="exportExcel()">导出excel</el-button>
      <el-button class="vd_export" icon="el-icon-refresh-right" size="small" type="info" @click="buttonRefresh()">刷新 </el-button>
    </div>
    <el-radio-group class="vg_mb_8" size="small" v-model="requ_type" @input="val => getTableData()">
      <el-radio-button :label="1">毛绒</el-radio-button>
      <el-radio-button :label="2">辅材</el-radio-button>
      <el-radio-button :label="3">非毛绒</el-radio-button>
    </el-radio-group>
    <DynamicUTable
      ref="multiTable"
      v-loading="loading"
      :columns="tableProperties"
      :need-check-box="false"
      :need-pagination="true"
      :need-search="true"
      :tableData="tableData"
      :total-page="total"
      @getTableData="getTableData"
      :page-size="50"
    >
    </DynamicUTable>
  </el-card>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getDate } from '@assets/js/dateUtils';
import { cloneDeep } from 'lodash';
import { requAPI } from '@api/modules/requ';
import { downloadFile } from '@api/public';

export default {
  name: 'RequBackDetail',
  components: { DynamicUTable },
  data() {
    return {
      dateRange: [],
      tableData: [],
      total: null,
      loading: true,
      requ_type: 1,
      tableProperties: [
        {
          prop: 'apprStffNameList',
          itemType: 'select',
          options: [],
          label: '审批人',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 'auto',
          needOtherColumn: true,
          formatter: row => row.appr_stff_name
        },
        {
          label: '委托打样单号',
          prop: 'requ_no',
          itemType: 'input',
          input: true,
          sortable: false,
          labelWidth: 'auto',
          widthAuto: true
        },
        {
          prop: 'requStffNameList',
          itemType: 'select',
          options: [],
          label: '打样委托人',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 'auto',
          needOtherColumn: true,
          formatter: row => row.requ_stff_name
        },
        {
          prop: 'custAbbrList',
          itemType: 'select',
          options: [],
          label: '客户简称',
          sortable: false,
          multiple: true,
          filterable: true,
          collapseTags: true,
          labelWidth: 'auto',
          needOtherColumn: true,
          formatter: row => row.cust_abbr
        },
        {
          label: '退单时间',
          prop: 'create_time',
          itemType: 'date',
          valueFormat: 'timestamp',
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: val => getDate(val, true),
          labelWidth: 'auto'
        },
        {
          label: '退单原因',
          prop: 'appr_inst_remark',
          itemType: 'input',
          input: true,
          sortable: false,
          widthAuto: true,
          labelWidth: 'auto'
        }
      ]
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      searchForm.requ_type = this.requ_type;
      searchForm.create_time = searchForm.create_time ? searchForm.create_time / 1000 : null;
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.requStffNameList = searchForm.requStffNameList?.toString();
      searchForm.apprStffNameList = searchForm.apprStffNameList?.toString();
      requAPI.get_requ_appr_inst(searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.total = data.total;
        this.tableProperties.forEach(item => {
          if (item.prop === 'custAbbrList') item.options = data.custAbbrList;
          if (item.prop === 'requStffNameList') item.options = data.requStffNameList;
          if (item.prop === 'apprStffNameList') item.options = data.apprStffNameList;
        });
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    buttonRefresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.startTime = startTime ? startTime / 1000 : null;
      searchForm.endTime = endTime ? endTime / 1000 : null;
      searchForm.requ_type = this.requ_type;
      searchForm.create_time = searchForm.create_time ? searchForm.create_time / 1000 : null;
      searchForm.custAbbrList = searchForm.custAbbrList?.toString();
      searchForm.requStffNameList = searchForm.requStffNameList?.toString();
      searchForm.apprStffNameList = searchForm.apprStffNameList?.toString();
      requAPI.export_requ_appr_inst(searchForm).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '' });
      });
    }
  }
};
</script>

<style></style>
